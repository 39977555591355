export const TermTypes = {
  GENERAL_TERMS: 'GENERAL_TERMS',
  PRIVACY_TERMS: 'PRIVACY_TERMS',
  PERSONAL_DATA_TERMS: 'PERSONAL_DATA_TERMS',
  NON_RESIDENT_TERMS: 'NON_RESIDENT_TERMS',
  OLDER_18_TERMS: 'OLDER_18_TERMS',
  OWN_NAME_AND_INTEREST_TERMS: 'OWN_NAME_AND_INTEREST_TERMS',
  AUTHORIZED_REPRESENTATIVE_CONFIRMATION: 'AUTHORIZED_REPRESENTATIVE_CONFIRMATION',
};

export const termsDocType = {
  GENERAL: 'general',
  FRT: 'frt',
};

export const termsPath = {
  [termsDocType.GENERAL]: 'https://vnx-terms.s3.amazonaws.com/VNX-Commodity-Token-Terms.pdf',
  [termsDocType.FRT]: 'https://vnx-terms.s3.amazonaws.com/VNX-FRT-Terms.pdf',
};

export const ResponseStatus = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  VALIDATION_ERROR: 422,
  SERVER_ERROR: 500,
};

export const WIDTH_PANEL = 320;

export const CURRENT_DATE = new Date().toISOString().substring(0, 10);
export const FILES_HOST = `${process.env.REACT_APP_HOST_PROTOCOL}://${process.env.REACT_APP_HOST}/${process.env.REACT_APP_FILEAPI}`;
export const KYC_BASE_URL = process.env.REACT_APP_KYC_BASE_URL;
export const BASE_STYLE_URL = process.env.REACT_APP_BASE_STYLE_URL;
export const BLOCKCHAIN_HOST_URL = process.env.REACT_APP_BLOCKCHAIN_HOST_URL;
export const BLOCKCHAIN_TX_HOST_URL = BLOCKCHAIN_HOST_URL + '/tx/';
export const BLOCKCHAIN_ADDRESS_HOST_URL = BLOCKCHAIN_HOST_URL + '/address/';
export const REGEXP_NUM = /^[0-9](\d+)?(,\d{1,2})?$|^$/;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const KYC_PROCEDURE_INDIVIDUAL_GUIDE = process.env.REACT_APP_KYC_PROCEDURE_INDIVIDUAL_GUIDE;
export const KYC_PROCEDURE_LEGAL_ENTITY_GUIDE = process.env.REACT_APP_KYC_PROCEDURE_LEGAL_ENTITY_GUIDE;
export const KYC_FLOW_NAME_INDIVIDUAL = process.env.REACT_APP_KYC_FLOW_NAME_INDIVIDUAL;
export const KYC_FLOW_NAME_LEGAL_ENTITY = process.env.REACT_APP_KYC_FLOW_NAME_LEGAL_ENTITY;
export const VNX_SITE = process.env.REACT_APP_BASE_SITE;
export const FAQ_URL = process.env.REACT_APP_FAQ_URL;
export const TERMS_URL = process.env.REACT_APP_TERMS_URL;
export const KYC_WIDGET_URL = process.env.REACT_APP_KYC_WIDGET_URL;
export const SUPPORT_URL = process.env.REACT_APP_SUPPORT_URL;

import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

import { PositionTypes } from './models';

export const initialState = {
  fetchOne: initialFetching,
  fetchPositionsProductOnly: initialFetching,
  navigation: 1,
  openTrees: { metals: false, stablecoins: false, staking: false, account: false },
  windowSize: undefined,
  kycBannerSize: undefined,
  symbols: {},
  positionProducts: [],
  DGRPositionProducts: [],
  FRTPositionProducts: [],
  structuredDepositPositionProducts: [],
  cryptoBlockchains: [],
};

const symbiote = {
  fetchOne: createFetching('fetchOne'),
  fetchSymbols: createFetching('fetchSymbols'),
  fetchPositionsProductOnly: createFetching('fetchPositionsProductOnly'),
  fetchCryptoBlockchains: createFetching('fetchCryptoBlockchains'),
  setNav: (state, data) => ({ ...state, navigation: data }),
  setOpenTrees: (state, data) => ({ ...state, openTrees: { ...state.openTrees, ...data } }),
  setWindowSize: (state, data) => ({ ...state, windowSize: data }),
  setKYCBannerSize: (state, data) => ({ ...state, kycBannerSize: data }),
  setAllSymbols: (state, data) => {
    const symbols = {};
    data.forEach((item) => {
      symbols[item.id] = {
        ...item,
      };
    });
    return { ...state, symbols: symbols };
  },
  setPositionProducts: (state, data) => ({
    ...state, positionProducts: data,
    DGRPositionProducts: data.filter(el => el.symbolType === PositionTypes.DGR),
    FRTPositionProducts: data.filter(el => el.symbolType === PositionTypes.FRT),
    structuredDepositPositionProducts: data.filter(el => el.symbolType === PositionTypes.STRUCTURED_DEPOSIT),
  }),
  setCryptoBlockchains: (state, data) => ({ ...state, cryptoBlockchains: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiote, 'common');

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useIdleTimer } from 'react-idle-timer';
import React, { Fragment, useCallback, useEffect } from 'react';

import { authenticatedSelector, eventIdleUpdateSelector } from '../selectors';
import { logout } from '../effects/logout';

const mapStateToProps = state => ({
  authenticated: authenticatedSelector(state),
  eventIdleUpdate: eventIdleUpdateSelector(state),
});
const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout),
});

const IdleTimeoutView = ({ children, authenticated, eventIdleUpdate, logout }) => {
  const navigate = useNavigate();

  const logoff = useCallback(async () => {
    await logout();
    navigate('/join');
  }, [ logout ]);

  const onIdle = () => {
    logoff();
  };

  const onAction = () => {
    console.info('AUTOLOGOFF minutes: ', Number(process.env.REACT_APP_AUTOLOGOFF), ' AUTOLOGOFF ms: ', Number(process.env.REACT_APP_AUTOLOGOFF) * 60 * 1000);
    console.info('time set: ', new Date());
  };

  const { reset, pause, getRemainingTime } = useIdleTimer({
    onAction,
    onIdle,
    crossTab: true,
    syncTimers: true,
    timeout:  Number(process.env.REACT_APP_AUTOLOGOFF) * 60 * 1000,
    throttle: 15000,
  });

  useEffect(() => {
    if (authenticated) {
      reset();
    } else {
      pause();
    }
  }, [ eventIdleUpdate, authenticated, reset, pause ]);

  //TODO убрать после тестов
  useEffect(() => {
    const interval = setInterval(() => console.info(`Remaining Time: ${Math.trunc(getRemainingTime() / 1000)}s`), 15000);
    return () => {
      clearInterval(interval);
    };
  }, [ getRemainingTime ]);

  return <Fragment>{children}</Fragment>;
};

export const IdleTimeout = connect(mapStateToProps, mapDispatchToProps)(IdleTimeoutView);

import { initialFetching, createFetching } from 'symbiote-fetching';
import { createSymbiote } from 'redux-symbiote';

import { AssetSymbols } from 'features/common/models';

export const initialPosition = { availFunds: 0, availVNX: 0, needFunds: 0, needVNX: 0, quantityVNX: 0 };
export const initialPositionGolden = { avail: 0, need: 0, balance: 0 };
export const initialPositionProduct = { balance: 0, avail: 0, availOffer: 0 };
export const initialState = {
  fetchOffering: initialFetching,
  fetchOffers: initialFetching,
  fetchOrder: initialFetching,
  fetchResetOrder: initialFetching,
  fetchPosition: initialFetching,
  fetchResetPosition: initialFetching,
  fetchResetGoldPosition: initialFetching,
  fetchSymbols: initialFetching,
  fetchExchangeRateCrypto: initialFetching,
  fetchDashboardHotOfferings: initialFetching,
  fetchPromoCards: initialFetching,
  fetchDashboardStructuredProducts: initialFetching,
  fetchGetDirectSaleOfferPrice: initialFetching,
  fetchgetDirectSaleGoldBars: initialFetching,
  fetchResetStatusKraken: initialFetching,
  fetchResetExchangeRatesKraken: initialFetching,
  fetchAvailSymbols: initialFetching,
  promoCards: [],
  dashboardStructuredProducts: [],
  productOffers: [],
  offering: {},
  offers: [],
  position: initialPosition,
  positionProduct: initialPositionProduct,
  positionGolden: initialPositionGolden,
  positionCurrent: initialPosition,
  symbols: [],
  allSymbols: [],
  order: {},
  offerPrice: {},
  goldBars: {},
  availSymbols: [],
};

const symbiotes = {
  fetchOffering: createFetching('fetchOffering'),
  fetchDashboardHotOfferings: createFetching('fetchDashboardHotOfferings'),
  fetchPromoCards: createFetching('fetchPromoCards'),
  fetchDashboardStructuredProducts: createFetching('fetchDashboardStructuredProducts'),
  fetchProductOffers: createFetching('fetchProductOffers'),
  fetchOffers: createFetching('fetchOffers'),
  fetchOrder: createFetching('fetchOrder'),
  fetchResetOrder: createFetching('fetchResetOrder'),
  fetchPosition: createFetching('fetchPosition'),
  fetchResetPosition: createFetching('fetchResetPosition'),
  fetchSymbols: createFetching('fetchSymbols'),
  fetchExchangeRateCrypto: createFetching('fetchExchangeRateCrypto'),
  fetchGetDirectSaleOfferPrice: createFetching('fetchGetDirectSaleOfferPrice'),
  fetchgetDirectSaleGoldBars: createFetching('fetchgetDirectSaleGoldBars'),
  fetchResetStatusKraken: createFetching('fetchResetStatusKraken'),
  fetchResetGoldPosition: createFetching('fetchResetGoldPosition'),
  fetchResetProductPosition: createFetching('fetchResetProductPosition'),
  fetchResetExchangeRatesKraken: createFetching('fetchResetExchangeRatesKraken'),
  fetchAvailSymbols: createFetching('fetchAvailSymbols'),
  setPromoCards: (state, data) => ({ ...state, promoCards: data }),
  setDashboardStructuredProducts: (state, data) => ({
    ...state,
    dashboardStructuredProducts: data,
  }),
  setProductOffers: (state, data) => ({
    ...state,
    productOffers: data,
  }),
  setOffering: (state, data) => ({ ...state, offering: data }),
  setPosition: (state, data) => ({ ...state, position: data }),
  setPositionProduct: (state, data) => ({ ...state, positionProduct: data }),
  setPositionGolden: (state, data) => ({ ...state, positionGolden: data }),
  setSymbols: (state, data) => {
    let symbols = data.filter(value => [ AssetSymbols.EUR, AssetSymbols.BTC, AssetSymbols.ETH, AssetSymbols.USDC ].includes(value.ticker));
    return { ...state, symbols: symbols };
  },
  setAllSymbols: (state, data) => ({ ...state, allSymbols: data }),
  setOrder: (state, data) => ({ ...state, order: data }),
  setOfferPrice: (state, data) => {
    let newArray = {};
    newArray[data?.id] = { ...data };
    return { ...state, offerPrice: { ...state.offerPrice, ...newArray } };
  },
  setGoldBars: (state, data) => ({ ...state, goldBars: data }),
  setAvailSymbols: (state, data) => ({ ...state, availSymbols: data }),
};

export const { actions, reducer } = createSymbiote(initialState, symbiotes, 'offering');


import { isJsonString } from 'lib/string';
import { getUserBrowser } from 'lib/helpers';
import { refresh } from 'features/auth/effects/refresh-token';

import { TaskQueue } from './taskQueue';
import { ErrorTypes } from './models';

export const TOKEN_ID = 'connect.sid';

let taskQueue = new TaskQueue();

export const request =
  (method, url, options = {}, isRepeat = false) =>
    (dispatch, getState) => {
      const defaultOptions = {};

      let accessToken = JSON.parse(isJsonString(localStorage.getItem('accessToken')) ? localStorage.getItem('accessToken') : '""');
      let appDeviceId = JSON.parse(isJsonString(localStorage.getItem('appDeviceId')) ? localStorage.getItem('appDeviceId') : '""');
      let timeZone = JSON.parse(isJsonString(localStorage.getItem('timeZone')) ? localStorage.getItem('timeZone') : '""');

      const headers = new Headers({
        ...options.headers,
        ...createContentType(options),
        ...createAuthorization(accessToken),
        ...createDeviceId(appDeviceId),
        ...createSessionId(timeZone),

        ...options.headers,
      });

      const baseUrl = '/api';
      const host = '';
      const uri = `${host}${options.baseUrl === '' ? '' : baseUrl}${url}`;
      const config = new Request(uri, {
        method,
        headers,
        ...defaultOptions,
        ...options,
        body: createBody(options, headers),
      });

      return fetch(config).then(async (response) => {
        if (response.status === 401) {
          const code = await response.clone().json().then(data => Promise.resolve(data?.code));
          if (code === ErrorTypes.JWT_EXPIRED || code === ErrorTypes.REFRESH_JWT_EXPIRED) {
            if (url !== '/auth/refresh' && !isRepeat) {
              if (!taskQueue.isRefreshing) {
                taskQueue.setIsRefreshing(true);
                const refreshFunc = async () => {
                  const { ok } = await dispatch(refresh);
                  if (ok) {
                    taskQueue.setIsRefreshing(false);
                    let newResponce = await taskQueue.addTaskAndRun(() => dispatch(request, method, url, options, true));
                    return newResponce;
                  }
                };

                let resp = await refreshFunc();
                taskQueue.setIsRefreshing(false);
                return resp;
              } else {
                let newResponce = await taskQueue.addTask(() => dispatch(request, method, url, options, true));
                return newResponce;
              }
            } else if (url === '/auth/refresh') {
              taskQueue.run(true);
            }
          }
        }

        if (options.parse === 'text') {
          return response.text();
        }
        if (options.parse === 'noparse') {
          return response;
        }
        const contentType = response.headers.get('Content-Type');
        if (contentType?.includes('json')) {
          return response.json().then(data => Promise.resolve({ ...data, status: response.status, source_data: data }));
        }
        if (contentType?.includes('text/html')) {
          return response.text().then(data => Promise.resolve({ data, status: response.status, source_data: data }));
        }
        if (contentType && [ 'application/octet-stream', 'application/pdf' ].includes(contentType)) {
          return response.blob().then((data) => {
            const url = URL.createObjectURL(data);
            return Promise.resolve({
              url,
              status: response.status,
            });
          });
        }
        throw new TypeError('Unexpected content-type');
      });
    };

const createAuthorization = accessToken => (accessToken ? { Authorization: `Bearer ${accessToken}` } : {});

const createDeviceId = appDeviceId => (appDeviceId ? { 'X-App-Device-Id': appDeviceId } : {});

export const createSessionId = (timeZone) => {
  const browser = getUserBrowser();
  const start = window.navigator.userAgent.indexOf('(') + 1;
  const end = window.navigator.userAgent.indexOf(')');
  const OS = window.navigator.oscpu || window.navigator.userAgent.slice(start, end);
  return { 'X-Session-Id': `${browser}, ${OS}${timeZone ? ', ' + timeZone : ''}` };
};

const createContentType = (options) => {
  const header = contentTypeFromOptions(options);

  return header ? { 'Content-Type': header } : {};
};

const contentTypeFromOptions = options =>
  typeof options.body === 'object' && !(options.body instanceof FormData)
    ? 'application/json'
    : options.body instanceof FormData
      ? options.headers
      : options.headers?.['Content-Type'];

const createBody = (options, headers) => {
  if (options.body && headers.get('content-type').includes('json')) {
    return JSON.stringify(options.body);
  }
  return options.body;
};

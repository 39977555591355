import { withStyles } from 'tss-react/mui';
import React, { useEffect, useState } from 'react';
import MaterialTable from '@mui/material/Table';
import { TableBody, TableCell, TableHead, TableRow, Paper, TableSortLabel, Typography, Grid, Divider, Collapse, IconButton, TextField } from '@mui/material';
import { cx } from '@emotion/css';

import { ReactComponent as UpIcon } from 'assets/icons/Up.svg';

import { TooltipStyled } from './tooltip-styled';

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',

    [theme.breakpoints.down('sm')]: {
      width: `calc(100% + calc(${theme.spacing(4)} * 2))`,
      marginLeft: -theme.spacing(4),
      marginRight: -theme.spacing(4),
    },
  },
  table: {
    borderCollapse: 'separate',
    borderSpacing: '0 1px',
  },
  activeRow: {
    cursor: 'pointer',
  },
  row: {
    '& td:first-of-type': {
      borderTopLeftRadius: 10,
      borderBottomLeftRadius: 10,
    },
    '& td:last-of-type': {
      borderTopRightRadius: 10,
      borderBottomRightRadius: 10,
    },
    [theme.breakpoints.down('sm')]: {
      '& td:first-of-type, & td:last-of-type': {
        borderRadius: 0,
      },
    },
  },
  head: {
    textTransform: 'inherit',
    '& th': {
      paddingTop: 0,
    },
    '& th svg': {
      fill: '#9FA4AE !important',
    },
  },
  white: {
    background: '#fff',
  },
  colName: {
    color: '#9FA4AE',
  },
  gray: {
    '& tr': {
      '&:nth-of-type(4n+1), &:nth-of-type(4n+2)': {
        backgroundColor: '#F4F5F6',
      },
      '&:nth-of-type(4n), &:nth-of-type(4n+3)': {
        backgroundColor: '#FFF',
      },
    },
  },
  label: {
    width: '100px',
    fontSize: '15px',
    fontWeight: '700',
    marginRight: '25px',
  },
  divider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
  },
  collapseCell: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  collapse: {
    padding: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  moreInfo: {
    textAlign: 'end',
  },
  moreBtn: {
    padding: 0,
    height: '1em',
    width: '1em',
  },
  forMoblie: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  forDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  cell: {},
});

const Head = ({ columns, classes, sortDirection, withSort, handleSort, TableColLabel, renderActions }) => {
  const getCell = (value, index) => {
    const HeadCell = (
      <TableCell
        align={value.alignHead || 'left'}
        className={classes.cell}
        key={index + '_headerCell'}
        style={{ minWidth: value.minWidth, width: value.width }}
      >
        {value !== '' ? <TableColLabel filter={withSort} handleSort={handleSort} sortDirection={sortDirection} value={value} /> : ''}
      </TableCell>
    );
    return value.tooltip ? (
      <TooltipStyled
        key={index + '_tooltip'}
        placement="top"
        title={
          <Typography align="center" variant="body2">
            {value.tooltip}
          </Typography>
        }
      >
        {HeadCell}
      </TooltipStyled>
    ) : (
      HeadCell
    );
  };

  return (
    <>
      <TableRow className={cx(classes.head, classes.forMoblie)} key="headerMobile">
        {columns.slice(0, 2).map((value, index) => getCell(value, index))}
        {/* {renderActions && <TableCell className={classes.cell}> </TableCell>} */}
        <TableCell className={classes.cell}>
          <Typography className={cx(classes.colName, classes.moreInfo)} variant="body2">
            More
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow className={cx(classes.head, classes.forDesktop)} key="headerDesktop">
        {columns.map((value, index) => getCell(value, index))}
        {renderActions && <TableCell className={classes.cell}> </TableCell>}
      </TableRow>
    </>
  );
};

const Row = ({ row, index, handleRow, handleMouseOver, handleMouseOut, classes,
  renderActions, columns, isRowHasId, rowClass, editingCondition = () => {}, editingProps = {}, validationErrors }) => {
  const [ open, setOpen ] = useState(false);

  return (
    <>
      <TableRow
        className={cx(classes.row, rowClass ?? '')}
        data-id={isRowHasId ? row.id : null}
        data-rowclass={rowClass ? rowClass : null}
        onClick={() => handleRow && (row.id ? handleRow(row.id) : handleRow(index))}
        onKeyDown={(e) => {
          if (e.key === 'Enter') editingProps.handleSaveLabel();
          if (e.key === 'Escape') editingProps.handleStopEditLabel();
        }}
        onMouseOut={() => handleMouseOut && handleMouseOut(row.id ?? index)}
        onMouseOver={() => handleMouseOver && handleMouseOver(row.id ?? index)}
      >
        {Object.keys(row)
          .slice(0, 2)
          .map(key =>
            key === 'id' ? (
              <TableCell key={index + '_' + key} />
            ): (
              <TableCell className={classes.cell} key={index + '_' + key}>
                {editingCondition(row[key].id) ? (
                  <TextField
                    autoFocus
                    error={Boolean(validationErrors['Label'])}
                    helperText={validationErrors['Label']}
                    inputProps={{ maxLength:'40' }}
                    value={editingProps.label}
                    variant="standard"
                    onChange={e => editingProps.setLabel(e.target.value)}
                    onClick={e => e.stopPropagation()}
                  />
                ) : row[key].displayData}
              </TableCell>
            )
          )}
        <TableCell className={cx(classes.moreInfo, classes.forMoblie)} key={index + '_moreInfo'}>
          <IconButton
            className={classes.moreBtn}
            size="large"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            {open ? <UpIcon /> : <UpIcon style={{ transform: 'scale(1, -1)' }} />}
          </IconButton>
        </TableCell>

        {Object.keys(row)
          .slice(2)
          .map(key =>
            key === 'id' ? (
              <TableCell className={classes.forDesktop} key={index + '_' + key} />
            ) : (
              <TableCell className={cx(classes.cell, classes.forDesktop)} key={index + '_' + key}>
                {row[key].displayData}
              </TableCell>
            )
          )}
        {renderActions && (
          <TableCell className={cx(classes.cell, classes.forDesktop)} key={index + '_action'}>
            {renderActions(row, index)}
          </TableCell>
        )}
      </TableRow>

      <TableRow className={classes.forMoblie}>
        <TableCell className={classes.collapseCell} colSpan={3}>
          <Collapse unmountOnExit in={open} timeout="auto">
            {Object.keys(row)
              .slice(2)
              .map((key, keyIndex) =>
                key === 'id' ? null : (
                  <div key={keyIndex + '_detail'}>
                    {keyIndex !== 0 ? <Divider className={classes.divider} /> : null}
                    <Grid container alignItems="center" className={classes.collapse} justifyContent="space-between">
                      <Grid item className={classes.label}>
                        <Typography variant="inherit">{columns.find(col => col.name === key)?.title}</Typography>
                      </Grid>
                      <Grid item>{row[key].displayData}</Grid>
                    </Grid>
                  </div>
                )
              )}
            {renderActions && <div className={classes.actions}>{renderActions(row, index)}</div>}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const invertion = {
  desc: 'asc',
  asc: 'desc',
};

function TableView({
  className,
  classes,
  rows,
  columns,
  renderActions,
  isIdenticalLines = false,
  handleRow = null,
  handleMouseOver = null,
  handleMouseOut = null,
  active = false,
  withSort = true,
  isRowHasId = false,
  rowClass = '',
  editingCondition,
  editingProps,
  validationErrors = {},
}) {
  const [ columnToSort, setColumnToSort ] = useState('');
  const [ sortDirection, setSortDirection ] = useState('desc');
  const [ orderedRows, setOrderedRows ] = useState(rows);

  function TableColLabel({ filter, value, sortDirection, handleSort }) {
    if (filter === true && value.title !== '') {
      return (
        <TableSortLabel active={columnToSort === value.name ? true : false} direction={sortDirection} onClick={() => handleSort(value.name)}>
          <Typography className={classes.colName} variant="body2">
            {value.title}
          </Typography>
        </TableSortLabel>
      );
    } else {
      return value.title;
    }
  }

  const handleSort = (columnName) => {
    if (columnToSort === columnName) {
      setSortDirection(invertion[sortDirection]);
    } else {
      setSortDirection('asc');
    }
    setColumnToSort(columnName);
  };

  useEffect(() => {
    if (columnToSort) {
      const sortedRows = [ ...rows ];
      sortedRows.sort(function (a, b) {
        if (a[columnToSort].sortData < b[columnToSort].sortData) {
          return -1;
        }
        if (a[columnToSort].sortData > b[columnToSort].sortData) {
          return 1;
        }
        return 0;
      });
      if (sortDirection === 'desc') sortedRows.reverse();
      setOrderedRows(sortedRows);
    } else {
      setOrderedRows(rows);
    }
  }, [ rows, columnToSort, sortDirection ]);

  return (
    <Paper className={cx(classes.root, className)}>
      <MaterialTable className={classes.table}>
        {columns && (
          <TableHead>
            <Head
              TableColLabel={TableColLabel}
              classes={classes}
              columns={columns}
              handleSort={handleSort}
              renderActions={renderActions}
              sortDirection={sortDirection}
              withSort={withSort}
            />
          </TableHead>
        )}
        <TableBody className={!isIdenticalLines ? classes.gray : ''}>
          {orderedRows &&
            orderedRows.map((row, index) => (
              <Row
                active={active}
                classes={classes}
                columns={columns}
                editingCondition={editingCondition}
                editingProps={editingProps}
                handleMouseOut={handleMouseOut}
                handleMouseOver={handleMouseOver}
                handleRow={handleRow}
                index={index}
                isRowHasId={isRowHasId}
                key={index + '_' + row.id}
                renderActions={renderActions}
                row={row}
                rowClass={rowClass}
                validationErrors={validationErrors}
              />
            ))}
        </TableBody>
      </MaterialTable>
    </Paper>
  );
}

export const Table = withStyles(TableView, styles);

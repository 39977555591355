import { useEffect, useRef, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';

export const useWindowSize = (delay) => {
  const [ windowSize, setWindowSize ] = useState({
    width: undefined,
    height: undefined,
  });
  const isThrottled = useRef(null);
  useEffect(() => {
    function handleResize() {
      if (isThrottled.current) {
        return;
      }
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      if (delay) {
        isThrottled.current = true;
        setTimeout(() => {
          isThrottled.current = false;
        }, delay);
      }
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, [ delay ]);
  return windowSize;
};

export const useScript = async (resourceUrl, id = '', async = true, defer = false) => {
  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === 'production' || process.env.REACT_APP_PLATFORM === 'prod') {
      const script = document.createElement('script');
      script.src = resourceUrl;
      script.async = async;
      script.defer = defer;
      script.id = id;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [ resourceUrl, id, async, defer ]);
};

export const useScriptPayment = async (resourceUrl, id = '', async = true, defer = false) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = resourceUrl;
    script.async = async;
    script.defer = defer;
    script.id = id;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [ resourceUrl, id, async, defer ]);
};

export const useTitle = (title, subTitle = '') => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = `${prevTitle} - ${title}`;
    return () => {
      document.title = prevTitle;
    };
  }, [ title, subTitle ]);
};

export const trackPageHubSpot = (page, email) => {
  const _hsq = (window._hsq = window._hsq || []);

  setTimeout(() => {
    if (!!(window._hsq && window._hsq.push !== Array.prototype.push)) {
      _hsq.push([ 'setPath', `${page.pathname}` ]);
      _hsq.push([
        'identify',
        {
          email: `${email}`,
        },
      ]);
      _hsq.push([ 'trackPageView' ]);
    }
  }, 0);
};

export const useHubSpotHistoryListen = (location, email) => {
  useEffect(() => {
    if (process.env.REACT_APP_PLATFORM === 'production' || process.env.REACT_APP_PLATFORM === 'prod') {
      trackPageHubSpot(location, email);

      return () => {};
    }
  }, [ location, email ]);
};

export const useTimeInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [ callback ]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [ delay ]);
};

export const useWidth = () => {
  const theme = useTheme();
  const keys = [ ...theme.breakpoints.keys ].reverse();
  return (
    keys.reduce((output, key) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

import { useIntercom } from 'react-use-intercom';
import { connect } from 'react-redux';
import { useEffect, useCallback, useMemo } from 'react';

import { KYCClientTypes } from '../kyc/models';
import { INTERCOM_APP_ID } from '../common/constants';
import { authClientIdSelector, authIntercomHashSelector, authLoginSelector, authUserIdSelector } from '../auth/selectors';
import { clientSelector } from '../account/selectors';
import { getClient } from '../account/effects';

const mapDispatchToProps = dispatch => ({
  getClient: () => dispatch(getClient),
});

const mapStateToProps = state => ({
  client: clientSelector(state),
  clientId: authClientIdSelector(state),
  login: authLoginSelector(state),
  userId: authUserIdSelector(state),
  userHash: authIntercomHashSelector(state),
});

const IntercomView = ({ client, clientId, login, userId, userHash, getClient }) => {
  const { update } = useIntercom();
  const intercomUser = useMemo(
    () => ({
      appId: INTERCOM_APP_ID,
    }),
    []
  );

  const getAsyncClient = useCallback(async () => {
    if (!clientId) {
      await getClient();
    }
  }, [ clientId, getClient ]);

  const getName = useCallback((item, login) => {
    if (item) {
      if (item.type === KYCClientTypes.LEGAL_ENTITY) {
        if (item.companyName && item.companyName !== login) {
          return item.companyName;
        }
      }
      if (item.type === KYCClientTypes.INDIVIDUAL) {
        if (item.firstName && item.firstName !== login) {
          return `${item.firstName ?? ''} ${item.secondName ?? ''} ${item.lastName ?? ''}`.replace(/ +/g, ' ').trim();
        }
      }
      return login;
    }
  }, []);

  useEffect(() => {
    getAsyncClient();
  }, [ clientId, getAsyncClient ]);

  const updateIntercom = useCallback(
    (args) => {
      update(args);
    },
    [ update ]
  );

  useEffect(() => {
    if (client && Object.entries(client).length !== 0) {
      updateIntercom({
        ...intercomUser,
        userId: userId,
        email: login,
        name: getName(client, login),
        userHash: userHash,
        createdAt: Number(client.createdAt) / 1000 });
    } else {
      updateIntercom({ ...intercomUser, name: '', email: '', userId: '', userHash: '' });
    }
  }, [ client, userId, login, userHash, getName, updateIntercom, intercomUser ]);
  return null;
};

export const Intercom = connect(mapStateToProps, mapDispatchToProps)(IntercomView);

export const mapSymbols = items =>
  items.map(item => ({
    isSupported: item.IsSupported,
    parentId : item.ParentId ,
    ticker: item.Ticker,
    name: item.Name,
    type: item.Type,
    id: item.id,
    logoDgr: item.DirectSaleOffer?.LogoDgr || item.Parent?.DirectSaleOffer?.LogoDgr || '',
  }));

export const mapPositionProducts = items => items.map(item => ({
  id: item.id,
  productType: item.ProductType,
  name: item.Name,
  ticker: item.Ticker,
  symbolType: item.SymbolType,
}));

export const mapBlockchains = (items) => {
  const blockchains = {};
  items.forEach((element) => {
    blockchains[element.blockchainId] = {
      icon: element.ui?.icon,
      name: element.ui?.name,
      caption: element.ui?.address_caption,
      supported: element.supported,
      isActive: element.status,
    };
  });
  return blockchains;
};
